<template>
    <q-item>
      <q-item-section top><q-item-label caption>
        <slot name="label"></slot>
      </q-item-label></q-item-section>
      <q-item-section><q-item-label  class="text-h6 text-right">
        <slot name="text"></slot>
      </q-item-label></q-item-section>
    </q-item>
</template>
  
<style>
</style>