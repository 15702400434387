<template>
  <div class="q-pa-md">
    <q-list 
    v-model="selOdg"
    :dense="dense"
    >
    <q-item  v-for="(o,idx) in details.odgs" :key="idx" clickable v-ripple  dense bordered padding class="rounded-borders">
      <q-item-section
        @click="aSelectOdg(o)"
      >
      <span
        class="rounded-borders"
        v-bind:class="{'bg-info': selOdg.id==o.id}"
      >{{ o.ime }}</span>
        <q-list v-if="selOdg.id==o.id" dense style="max-height: 500px; overflow:auto;">
          <q-item v-for="(z, zidx) in getOdgZapisi(id=o.id)" :key="zidx">
            <q-item-section v-if="z.odg_id==o.id">
              <q-item-label caption class="small">{{ getTockaIme( z.tocka_id ) }}</q-item-label>
              <q-item-label>{{ z.zapis }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-item-section>
    </q-item>
    </q-list>
  </div>
</template>

<style scoped>
  .rounded-borders {
    border-radius: 5px;
  }
  .bg-info {
    background-color: #e8f0fe;
  }
  .text-caption.small {
    font-size: 0.7em;
  }
</style>
<script>
import { ref, computed, nextTick  } from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OdgovoriTab",
  data() {
    return {
      dense: ref(true),
      details: {},
      zapisi: ref([]),
      selOdg: ref({}),
    };
  },
  mutations: {
    selOdg(state, o) {
      console.log("selOdg", o);
      state.selOdg = o;
    },
  },
  computed: {
    ...mapGetters(['getDetails','getSelVpr','getSelOdg']),
    getIme(o) { return o.ime; },
    getTockaIme: (state) => (id) => {
      console.log("getTockaIme", id);
      var tocka = '';
      state.details.mreza.find((el) => {
        if(el.id == id) {
          tocka = el.ime + " " + el.opis;
          return true;
        }
      });
      return tocka;
    },
    getOdgZapisi: (state) => (id) => {
      console.log("getOdgZapisi", id);
      var odgzaps = [];
      for( var i=0; i<state.zapisi.length; i++) {
          var zap = state.zapisi[i];
          if(zap.odg_id == id) {
            odgzaps.push(zap);
          }
      }
      return odgzaps;
    },


  },
  methods: {
    ...mapActions(['selectOdg']),
    aSelectOdg(o) {
      console.log("aSelectOdg", o);
      this.details = this.getDetails;
      this.zapisi = this.details.zapisi;
      //commit("selectOdg", o.ime);
      this.selOdg = o;
      //this.sSelOdg = o.ime;
      // trenutno ne rabimo odgovora drugje this.$store.dispatch("selectOdg", o.ime);
      // this.$store.commit("setSelOdg", o, { root: true });
    },
  },
  
  watch: {
    getDetails(newVal) {
      console.log("odgovori", newVal);
      this.details = newVal;
    },

  },
  actions: {
  },
  mounted() {
    console.log("odgovori", this);
    this.details = this.getDetails

  }
};
</script>