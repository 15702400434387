<template>
    <q-item  clickable v-ripple>
      <q-item-section avatar class="simbola">
        <slot name="znak"></slot>
      </q-item-section>
      <q-item-section>
        <slot name="text"></slot>
      </q-item-section>
    </q-item>
</template>
  
<style>
</style>