<template>
  <div>
  <q-select 
      v-focus
      styleXX="width: 250px"
      use-input
      hide-selected
      fill-input
      input-debounce="0"
      :label='$gettext("Question")'
      @filter="filterFn"
      @filter-abort="abortFilterFn"
      square
      outlined
      filled
      v-model="selVpr"
      :dense="dense"
      :options="optVprs"
      option-value="value"       option-label="label"
      :loading="loading"
      hintXX="ključna beseda ali zaporedna št."
      placeholderXX="izberi question"
      @update:model-value="onVprChange()"
    >
  </q-select>
  <q-separator />
  <q-list v-if="typeof details.karta !== 'undefined'" :dense="dense">
    <ItemLayout><template #label>{{ details.karta.l_stevilka }}</template><template #text>{{ details.karta.stevilka }}</template></ItemLayout>
    <ItemLayout><template #label>{{ details.karta.l_ime }}</template><template #text>{{ details.karta.ime }}</template></ItemLayout>
    <ItemLayout><template #label>{{ details.karta.l_objava }}</template><template #text>{{ details.karta.objava }}</template></ItemLayout>
    <ItemLayout><template #label>{{ details.karta.l_avtor }}</template><template #text>{{ details.karta.avtor }}</template></ItemLayout>
  </q-list>
  <q-separator />

  <!-- div v-if="typeof details.odgs !== 'undefined'" class="">
    <div top caption>Odgovori</div>
    <div class="row items-start q-gutter-md">
        <div class="flex col flex-top">
    <ul class="odgs st1">
    <li v-for="(odg,idx) in details.odgs" :key="idx" class="row"><span  v-if="idx < 35" class="zrcola" v-bind:data-id="idx">{{ odg.ime }}</span></li>
    </ul>
        </div>
        <div class="flex col flex-top">
    <ul class="odgs st2">
    <li v-for="(odg,idx) in details.odgs" :key="idx"  class="row"><span  v-if="idx >= 35" class="row zrcola" v-bind:data-id="idx">{{ odg.ime }}</span></li>
    </ul>
        </div>
    </div>
  </div -->

  <!-- q-list v-if="typeof details.odgs !== 'undefined'">
    <div top caption>Odgovori</div>
    <ul v-for="(odg,idx) in details.odgs" :key="idx">
    <li><span class="zrcola">{{ odg.ime }}</span></li>
    </ul>
  </q-list -->

  <!-- h5>ZAPIS</h5> option-value="ime"       option-label="opis"       @input="onVprChange($event)"       :value="value"       @change="onVprChange($event)"
      emit-value

  <q-separator />
  <h5>FONETIČNI ZAPIS</h5>
  <q-separator />
  <h5>FONOLOŠKI OPISI</h5>
  <q-list>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="picture_as_pdf" />
      </q-item-section>
      <q-item-section>Povezava 1</q-item-section>
    </q-item>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="picture_as_pdf" />
      </q-item-section>
      <q-item-section>Povezava 2</q-item-section>
    </q-item>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="picture_as_pdf" />
      </q-item-section>
      <q-item-section>Povezava 3</q-item-section>
    </q-item>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="picture_as_pdf" />
      </q-item-section>
      <q-item-section>Povezava 4</q-item-section>
    </q-item>
  </q-list>
  <q-separator />
  <h5>POVEZAVE</h5>
  <q-list>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="link" />
      </q-item-section>
      <q-item-section>Fran</q-item-section>
    </q-item>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="link" />
      </q-item-section>
      <q-item-section>SSZJ</q-item-section>
    </q-item>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="link" />
      </q-item-section>
      <q-item-section>Wiki</q-item-section>
    </q-item>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="link" />
      </q-item-section>
      <q-item-section>SICRIS</q-item-section>
    </q-item>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="link" />
      </q-item-section>
      <q-item-section>korpus</q-item-section>
    </q-item>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="link" />
      </q-item-section>
      <q-item-section>FO</q-item-section>
    </q-item>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="volume_up" />
      </q-item-section>
      <q-item-section>Posnetek</q-item-section>
    </q-item>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="flip" />
      </q-item-section>
      <q-item-section>Listek</q-item-section>
    </q-item>
    <q-item clickable v-ripple>
      <q-item-section avatar>
        <q-icon color="primary" name="link" />
      </q-item-section>
      <q-item-section>Fran</q-item-section>
    </q-item>
  </q-list -->
  <q-separator />
  </div>
</template>

<style lang="sass" scoped>
.odgs
  padding: 0
  margin: 0
  li
    list-style-type: none
    padding: 0
    margin: 0

</style>

<script>
import ItemLayout from './ItemLayout.vue'
import { ref, computed, nextTick  } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
//import { mapFields } from 'vuex-map-fields';
import store from "../store/index";

var allOptions = [];
//var selVpr=ref('');



function ItemLayout_NE_DELA_PRAV(item) {
  return `<q-item>
      <q-item-section top><q-item-label caption>${ item.label }</q-item-label></q-item-section>
      <q-item-section><q-item-label  class="text-subtitle2 text-right">${ item.text }</q-item-label></q-item-section>
    </q-item>`
}

export default {
  name: "QuestionTab",
  data() {
    return {
      dense: ref(true),
      optVprs: ref([]),
      modVprs: ref(''),
      loading: ref(true),
      details: ref({}),
      selVpr: ref(''),

    };
  },

  components: {
    ItemLayout
  },

  computed: {
    ...mapGetters(["getVprs",'getDetails','getSelVpr',"getLayerTocke"]),
    //...mapFields(['sDetails','sVprs','sSelvpr','sMrezaTockeSrc']),
    ...mapMutations([
            "sVprs",
            "sDetails",
            "sSelvpr",
            "sSelOdg",
            "sSelTocka",
            "sMrezaTockeSrc",
        ]),
  },

  methods: {
    ...mapActions(["replaceTocke","replaceOdgovori"]),

  /*
    focusInput: function ( inputRef ) {
      // $refs is an object that holds the DOM references to your inputs
      this.$refs[inputRef].focus();
    },
    */
    onVprChange( val ) {
        // something change
        //selVpr = val;
        console.log("change", val, this.sSelvpr, this.selVpr);
        store.commit('sSelvpr', this.selVpr.value);
        store.dispatch("loadVprDetails", this.selVpr.value);
    },
    filterFn (val, update, abort) {
      // call abort() at any time if you can't retrieve data somehow
      console.log('filter')

      setTimeout(() => {
        update(() => {
          this.loading = true
          if (val === '') {
            this.optVprs = allOptions
          }
          else {
            const needle = val.toLowerCase()
            this.optVprs = allOptions.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
          }
          this.loading = false
        })
      }, 500)
    },

    abortFilterFn () {
      console.log('delayed filter aborted')
    }
  },

  mounted: function() {
  //  //this.focusInput('select_vpr');
    this.selVpr = this.getSelVpr;
    this.details = this.getDetails;

    if(this.details.hasOwnProperty('karta')) {
      console.log("questionTab remounted");
      this.loading = false;
      return;
    }

    console.log("QuestionTab mounted", allOptions.length);

    this.loading = (allOptions.length == 0);
    if(this.loading) {
      //store.dispatch("loadVprs"); premaknjeno v app
      //store.dispatch("loadVprDetails"); // naložimo samo mrežo
    //} else {
    //  this.details = allOptions; // če ne se naloži v watch
    }

  },

  created() {
    console.log("QuestionTab created", allOptions.length, typeof this.getDetails);

    //if( typeof this.getDetails == 'undefined') {
    //  console.log("loadVprs - no details");
    //  store.dispatch("loadVprs");
    //} else {
    //  this.details = this.getDetails;
    //}

    //store.dispatch("loadVprs");

    //if( typeof this.getSelVpr !== 'undefined')
    // !!!??? this.sSelvpr = "";


    //if(this.sSelvpr != "") {
    //  store.dispatch("loadVprDetails");
    //} else {
    //  this.optVprs = allOptions;
    //}

    //if( typeof this.getDetails !== 'undefined')
    //  this.details = this.getDetails;


    console.log("model", this);
    //this.$set(this.modVprs, 'test', 'goog')
  },


  watch: {
    getVprs(newVal) {
      console.log("new vprs in info QT", newVal);
      allOptions = [];
      newVal.forEach(element => {
        allOptions.push({label: element.ime + " " + element.opis, value: element.ime});
      });
      //allOptions = newVal;
      this.optVprs = allOptions;
      this.loading = false;
      //this.$forceUpdate();
      //this.sSelvpr = this.getSelVpr;
    },
    getDetails(newVal) {
      console.log("new details in info QT", newVal);
      this.details = newVal;
      this.replaceTocke(newVal.mreza);
      this.replaceOdgovori(newVal.zapisi);
    },
    getSelVpr(newVal) {
      console.log("new selvpr in info QT", newVal);
      this.selVpr = newVal;
    },
    getLayerTocke(newVal) {
      console.log("new getLayerTocke in info QT", newVal);
    },

  },

};
</script>
