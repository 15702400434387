import Vue from 'vue'
import Vuex from 'vuex'
//import VuexPersist from 'vuex-persist'

import { createStore } from "vuex";
import func from "./modules/func";
import auth from "./modules/auth";
import map from "./modules/map";
import answer from "./modules/answer";
import diatlas from "./modules/diatlas";
import data from "./modules/data";

import createPersistedState from "vuex-persistedstate";
import { getField, updateField } from 'vuex-map-fields';



/*
const vueLocalStorage = new VuexPersist({
  key: 'isla',
  storage: window.localStorage,
  reducer: (state) => ({
    auth: {
      user: state.auth.user,
    }
  })
})
*/
export default createStore({
  plugins: [createPersistedState({
    key: 'isla',
    paths: ['auth']
  })],
  modules: {
    auth,
    map,
    answer,
    diatlas,
    data,
    func,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
  },
  //plugins: [vueLocalStorage.plugin]
});
