import { createRouter, createWebHashHistory } from "vue-router";
import MapContainer from "../components/MapContainer.vue";
import InputContainer from "../components/InputContainer.vue";

const routes = [
  {
    path: "/",
    name: "Map",
    component: MapContainer,
    beforeEnter(to, from, next) {
      //console.log("beforeEnter " + to.path + " " + from.path + " " + window.location.href);
      if(window.location.pathname != "/") {
        //console.log("neobstaja");
        next('/');
        if(window.location.pathname == "/input")
          window.location = '/#/input';
        else
          window.location = '/';
        
        return false; // do sem ne pride, window.location preusmeri
      }
      next();
    }
  },
  {
    path: "/input",
    name: "Input",
    component: InputContainer,
    beforeEnter(to, from, next) {
      //console.log("beforeEnter input " + to.path + " " + from.path + " " + window.location.href);
      if(window.location.pathname != "/") {
        //console.log("neobstaja");
        next('/input');
        window.location = '/#/input';
        return false;
      }
      next();
    }
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../components/About.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
