<template>
  <div>
    <q-splitter v-model="splitterModel" horizontal>
      <template v-slot:before>
        <q-tabs v-model="tab" horizontal>
          <q-tab name="Question" :label='$gettext("Question")' />
          <q-tab name="Odgovori" :label='$gettext("Answers")' />
          <q-tab name="Gradivo" :label='$gettext("Material")' />
          <!-- q-tab name="Legenda" label="Legenda" />
          <q-tab name="Sloji" label="Sloji" / -->
        </q-tabs>
      </template>

      <template v-slot:after>
        <q-tab-panels
          v-model="tab"
          animated
          swipeable
          vertical
          transition-prev="jump-up"
          transition-next="jump-up"
        >
          <q-tab-panel name="Question">
            <question-tab></question-tab>
          </q-tab-panel>
          <q-tab-panel name="Gradivo">
            <gradivo-tab></gradivo-tab>
          </q-tab-panel>
          <q-tab-panel name="Odgovori">
            <odgovori-tab></odgovori-tab>
          </q-tab-panel>
          <!--q-tab-panel name="Legenda">
            <legend-tab></legend-tab>
          </q-tab-panel>
          <q-tab-panel name="Sloji">
            <layers-tab></layers-tab>
          </q-tab-panel-->
          
        </q-tab-panels>
      </template>
    </q-splitter>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex"
import store from "../store/index";
import QuestionTab from "./QuestionTab";
import OdgovoriTab from "./OdgovoriTab";
import GradivoTab from "./GradivoTab";
//import LegendTab from "./LegendTab";
//import LayersTab from "./LayersTab";

export default {
  name: "LeftDrawer",
  components: {
    QuestionTab,
    OdgovoriTab,
    GradivoTab,
    //LegendTab,
    //LayersTab,
  },

  data() {
    return {
      text: ref(""),
      ph: ref(""),
      dense: ref(false),
      tab: ref("Question"),
      splitterModel: ref(30),
    }
  },
  mountedXXX() {
    console.log("LeftDrawer mounted");

    // TODO get from session
    //this.sSelvpr = "";
    //this.sSelOdg = "";
    //this.sSelTocka = {};

    //store.dispatch("loadVprs");
    //store.dispatch("loadVprDetails");
  },
  createdXXX() {
    console.log("LeftDrawer created");

    // TODO get from session
    //this.sSelvpr = "";
    //this.sSelOdg = "";
    //this.sSelTocka = {};

    //store.dispatch("loadVprs");
    //store.dispatch("loadVprDetails");
  },
  computed: {
    ...mapMutations([
            "sSelvpr",
            "sSelOdg",
            "sSelTocka",
        ]),
      }
};
</script>
