<template>
  <div class="q-pa-md">
    <q-list>
      <q-expansion-item  v-for="(g,gidx) in layerState.sloji" :key="gidx"
        group="sloji"
        icon="layers"
        v-bind:label="$gettext(gidx)"
        default-opened
        header-class="text-primary"
      >
        <q-list :dense="dense">
          <q-item  v-for="(o,idx) in g" :key="idx" clickable v-ripple>
            <q-checkbox
              v-model="layerState.sloji[gidx][idx]"
              v-bind:label="$gettext(layerState.sloji_naslov[gidx][idx])"
              @click.prevent="this.selectSloj({gidx,idx,$event})"
            />
          </q-item>
        </q-list>
      </q-expansion-item>
    </q-list>
    <q-separator />
    <q-list>
    <q-expansion-item
      group="podlage"
      icon="map"
      :label='$gettext("Base maps")'
      default-opened
      header-class="text-primary"
    >
      <q-list :dense="dense">
        <q-item  v-for="(o,idx) in layerState.podlage" :key="idx" clickable v-ripple>
          <q-checkbox
              v-model="layerState.podlage[idx]"
              v-bind:label="$gettext(layerState.podlage_naslov[idx])"
              @click.prevent="this.selectPodlaga({idx,$event})"
            />
        </q-item>
        </q-list>
    </q-expansion-item>
  </q-list>
    <q-separator />
    <!-- q-list>
      <q-expansion-item
        group="extra-layers"
        icon="layers"
        label="Dodatni sloji"
        default-opened
        header-class="text-primary"
      >
        <q-list>
          <q-item>
            <q-checkbox
              v-model="auxLayer_0"
              label="Narečne skupine"
              @click.prevent="this.toggleBaseLayer('a0')"
            />
          </q-item>
          <q-item>
            <q-checkbox
              v-model="auxLayer_1"
              label="Točke"
              @click.prevent="this.toggleBaseLayer('a1')"
            />
          </q-item>
        </q-list>
      </q-expansion-item>

      <q-separator />

      <q-expansion-item
        group="base-layers"
        icon="map"
        label="Osnovni sloji"
        default-opened
        header-class="text-primary"
      >
        <q-list>
          <q-item>
            <q-checkbox
              id="b0"
              v-model="baseLayer_0"
              label="Podlaga"
              @click.prevent="this.toggleBaseLayer('b0')"
            />
          </q-item>
        </q-list>
      </q-expansion-item>
    </q-list-->
  </div>
</template>

<script>
import { ref } from "vue";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "LayersTab",
  data() {
    return {
      dense: ref(true),
      a_sloji: ref({}),
      auxLayer_0: ref(true),
      auxLayer_1: ref(true),
      baseLayer_0: ref(true),
    };
  },
  watch: {
    getLayerState(newVal) {
      console.log("layers 1", newVal);
      //this.layers = newVal;
    },

  },
  methods: {
    ...mapMutations(["toggleBaseLayer"]),
    ...mapGetters(['getLayerState']),
    ...mapActions(['selectPodlaga', 'selectSloj']),
  },
  computed: mapState({
        // arrow functions can make the code very succinct!
        layerState: state => state.map.layerState,
    
      }),
  mounted() {
    console.log("layers 2", this.layerState);
    //this.layers = this.getLayerState
  }
};
</script>