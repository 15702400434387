<template>
  <div class="q-pa-md">
    <q-list :dense="dense">
    <ZnakLayout v-for="(l,idx) in legenda" :key="idx">
      <template #znak>
        <span :style="'color:' + l.barva">{{ l.znak }}</span>
      </template>
      <template #text>
        {{ l.odg }}
      </template>
    </ZnakLayout>
    </q-list>
    <!-- q-list>
      <q-item clickable v-ripple>
        <q-item-section avatar>
          <q-icon color="primary" name="ni-zapisa" />
        </q-item-section>
        <q-item-section>Ni zapisa</q-item-section>
      </q-item>
    </q-list -->
  </div>
</template>

<script>
import ZnakLayout from './ZnakLayout.vue'

//import { mapFields } from 'vuex-map-fields';
import { ref, computed, nextTick  } from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LegendTab",
  data() {
    return {
      dense: ref(true),
      details: ref({}),
      legenda: ref([])
    };
  },
  computed: {
    ...mapGetters(['getDetails']),

  },
  components: {
    ZnakLayout
  },

  watch: {
    getDetails(newVal) {
      console.log("watch legenda", newVal);
      this.details = newVal;
      this.showLegenda();
    },

  },
  methods: {
    showLegenda() {
      console.log("show legenda", this.details);

      let legenda = new Map()
      if(this.details.legenda) {
        this.details.legenda.forEach(el => {
          legenda.set(el.znak, { odg:el.odg, barva:el.barva });
        });
      }

      this.legenda = [];
      for (const [key, value] of legenda.entries()) {
        this.legenda.push( {znak:key, odg:value.odg, barva:value.barva} );
      }

    },
  },
  mounted() {
    console.log("mounted legenda", this);
    this.details = this.getDetails
    this.showLegenda();
  }
};
</script>