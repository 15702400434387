<template>
    <!-- viri -->
    <div class="">
        <div v-if="!canGradivo">
            <q-card-section>
                <q-card>
                    <q-card-section>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $gettext("Please select point/question.") }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-card-section>
                </q-card>
            </q-card-section>
        </div>
        <div>
            <!-- wikipedia for getTockaOpis -->
            <q-list
                bordered
                separator
                class="plist"
                style=""
            >
                <q-item v-if="getSelTockaOpis"
                    clickable
                    v-ripple
                    @click="openGradivo( 'https://sl.wikipedia.org/wiki/' + getSelTockaOpis )"
                >
                    <q-item-section side>
                        <q-btn flat round dense color="primary"
                            icon="bi-wikipedia"
                        />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ getSelTockaOpis }}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item v-if="getSelVprOpis"
                    clickable
                    v-ripple
                    @click="openGradivo( 'https://sl.wikipedia.org/wiki/' + getSelVprOpis )"
                >
                    <q-item-section side>
                        <q-btn flat round dense color="primary"
                            icon="bi-wikipedia"
                        />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ getSelVprOpis }}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </div>
        <!-- div v-else>
            <q-card-section>
                <q-card>
                    <q-card-section>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $gettext("Gradivo za točko") }}: {{ getSelTocka.name }}
                                    <p v-if="getSelVpr">{{ getVprasanje(getSelVpr) }}</p>
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-card-section>
                </q-card>
            </q-card-section>
        </div -->
        <q-list
            bordered
            separator
            class="plist"
            v-if="getGradivo.length>0"
            style=""
        >
            <q-item
                v-for="gd,index in getGradivo"
                :key="index"
                clickable
                v-ripple
                @click="openGradivo(gd.file)"
                :style="gd.ext=='jpg' ? 'padding:0' : ''"
            >

                <q-item-section v-if="gd.ext=='jpg'">
                    <q-img
                        :src="brezShare(gd.file)"
                        style=""
                    />
                </q-item-section>
                <q-item-section v-else>
                    <q-item-label>{{ gd.ime }}</q-item-label>
                </q-item-section>
            </q-item>
        </q-list>

        <q-list
            bordered
            separator
            class="q-mb-md plist"
            v-if="getViri.length>0"
            :label='sources_list_label'
            style=""
        >
            <q-item
                v-for="vir,index in getViri"
                :key="index"
                clickable
                v-ripple
                @click="openVir(vir.url)"
            >
            <!-- q-item-section side>
                    <q-btn
                        flat
                        round
                        dense
                        icon="open_in_new"
                        color="primary"
                        @click="openVir(vir.url)"
                    />
            </q-item-section -->

            <q-item-section>
                <q-item-label>{{ vir.vir }}</q-item-label>
                <!-- q-item-label caption>{{ vir.url }}</q-item-label -->
                </q-item-section>
            </q-item>
        </q-list>

    </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { useQuasar } from "quasar";
import store from '../store';

export default {
    name: "GradivoTab",
    data() {
        return {
            sources_list_label: this.$gettext("Sources"),
            sVpr: "*",
            sToc: "*",
        };
    },
    computed: {
        ...mapGetters(["getViri", "getGradivo", "getVprasanje", "getSelVpr", "getSelTocka", "getIzbTocka",
            "getTockaOpis", "getVprOpis"]),
        canGradivo() {
            var isKnown = this.getSelTocka && !!this.getSelTocka.name;
            var isKnown2 = this.getSelVpr;
            console.log("canGradivo", isKnown, isKnown2);
            return isKnown || isKnown2;
        },
        getSelTockaOpis() {
            if (!this.getSelTocka) return "";
            return this.getSelTocka.desc;
        },
        getSelVprOpis() {
            return this.getVprOpis(this.getSelVpr);
        },
    },
    methods: {
        ...mapMutations(["selVir"]),
        openVir(url) {
            window.open(url, "_blank");
        },
        brezShare(file) {
            return file.replace("/share", "");
        },
        openGradivo(file) {
            console.log("openGradivo", file);
            // remove /share from file start
            let f = file.replace("/share", "");
            // open file
            window.open(f, "_blank");

            this.$q.notify({
                message: "openGradivo",
                color: "primary",
                position: "top",
                icon: "cloud_download",
            });
        },
    },
    watch: {
        getViri() {
            console.log("getViri - GT", this.getViri);
        },
        getGradivo() {
            console.log("getGradivo - GT", this.getGradivo);
        },
        getSelVpr(newVal) {
            console.log("new selvpr in info GT", newVal);
            this.sVpr = newVal;
            store.dispatch("loadGradivo", { vpr: this.getSelVpr, toc: this.getSelTocka.name });
            //store.dispatch("loadGradivo", { vpr: this.sVpr, toc: this.sToc });
        },
        getSelTocka(tocka) {
            console.log("watch getSelTocka - GT: " + tocka.name);
            this.sToc = tocka.name;
            store.dispatch("loadGradivo", { vpr: this.getSelVpr, toc: this.getSelTocka.name });
            //store.dispatch("loadGradivo", { vpr: this.sVpr, toc: this.sToc });

        },
        getIzbTocka(tocka) {
            console.log("watch getIzbTocka - GT: " + tocka.name);
            this.sToc = tocka.name;
            store.dispatch("loadGradivo", { vpr: this.getSelVpr, toc: this.getSelTocka.name });
        },

    },
    mounted() {
        console.log("mounted GradivoTab");
        store.dispatch("loadGradivo", { vpr: this.getSelVpr, toc: this.getSelTocka.name });
    },
};
</script>