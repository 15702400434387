import { createApp } from "vue";
import Vue from 'vue'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import axiosSetup from "@/helpers/axios";

import { createGettext } from "vue3-gettext";
import translations from './language/translations.json'

//Vue.component('country-flag', CountryFlag)

// Call the axios setup method to setup the interceptors
axiosSetup();

export const gettext = createGettext({
  availableLanguages: {
    sl: "Slovenščina",
    en: "English",
  },
  defaultLanguage: "sl",
  translations: translations,
});

const app = createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(gettext);

  // npm run gettext:extract extracts translation keys from your code and creates .po files to translate.
  // npm run gettext:compile compiles the translated messages from the .po files to a .json to be used in your application.
  // npm run gettext:watch watches for changes in your code and automatically extracts and compiles the translations.


  // Add a directive to focus an element
app.directive('focus', {
    mounted(el) {
      if(!gettext.current) gettext.current = store.getters.locale;
      console.log(gettext);
      el.focus();
    }
})


/* Optional: 
   Add a slight delay if the input does not focus.

app.directive('focus', {
    mounted(el) { // When the bound element is inserted into the DOM...
        setTimeout(() => {
            el.focus() // Focus the element
        }, 500)
    }
}) */

app.mount("#app");
