<template>
  <q-page>
    <div ref="map-root" class="map-root">
      <div id="tooltip" class="tooltip"></div>
    </div>
    <div style="height: 16px;"></div>
  </q-page>
</template>

<script>
import { ref } from "vue";
import View from "ol/View";
import Map from "ol/Map";
import Overlay from "ol/Overlay";

import {
  Pointer as PointerInteraction,
  defaults as defaultInteractions,
} from 'ol/interaction.js';

import Select from "ol/interaction/Select";
import GeoJSON from "ol/format/GeoJSON";
import { transform } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import TileWMS from "ol/source/TileWMS";
import {tile} from 'ol/loadingstrategy';
import {createXYZ} from 'ol/tilegrid';
import WFS from 'ol/format/WFS.js';
import { Fill, Stroke, Style, Circle, Text, Icon } from "ol/style";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import * as narSk from "@/static/aux_layers/narecne_skupine_2011.json";
import * as narTC from "@/static/aux_layers/tocke.json";
import "ol/ol.css";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import * as svgIcon0 from "@/static/selectedFonts/7051.svg";
import * as svgIcon1 from "@/static/selectedFonts/7100.svg";
import * as svgIcon2 from "@/static/selectedFonts/7200.svg";
import * as svgIcon3 from "@/static/selectedFonts/7580.svg";
import * as svgIcon4 from "@/static/selectedFonts/asterisk.svg";
import $ from "jquery";


class MapInteraciton extends PointerInteraction {
  constructor() {
    super({
      handleDownEvent: handleDownEvent,
      //handleDragEvent: handleDragEvent,
      //handleMoveEvent: handleMoveEvent,
      //handleUpEvent: handleUpEvent,
    });

    /**
     * @type {import("../src/ol/coordinate.js").Coordinate}
     * @private
     */
    this.coordinate_ = null;

    /**
     * @type {string|undefined}
     * @private
     */
    this.cursor_ = 'pointer';

    /**
     * @type {Feature}
     * @private
     */
    this.feature_ = null;

    /**
     * @type {string|undefined}
     * @private
     */
    this.previousCursor_ = undefined;
  }
}

/**
 * @param {import("../src/ol/MapBrowserEvent.js").default} evt Map browser event.
 * @return {boolean} `true` to start the drag sequence.
 */
 function handleDownEvent(evt) {
  const map = evt.map;

  const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    return feature;
  });

  if (feature) {
    this.coordinate_ = evt.coordinate;
    this.feature_ = feature;
  }

  return !!feature;
}

export default {
  name: "MapContainer",
  data() {
    return {
      baseMap: {},
    };
  },
  computed: {
    ...mapGetters(["getLayerState", "getActiveTab","getDetails","getLayerTocke","getOLMap","loggedIn"]),
    ...mapState({
        wms_layers: state => state.map.wms_layers,
        map_layer: state => state.map.map_layer,
        mreza_layer: state => state.map.sMrezaTockeLayer,
        odgovori_layer: state => state.map.sOdgovoriLayer,
    
      }),
  },

  watch: {
    /* povozi maps ... ne vem točno zakaj - prej je bil izključen ker sta bila dva watch
    getLayerState: {
      deep: true,
      handler(newVal) {
        const layers = this.baseMap.getLayers();
        layers.forEach((layer) => {
          layer.setVisible(newVal[layer.get("id")]);
        });
      },
    },*/
    getDetails(newVal) {
      console.log("new details in mapvue", newVal);
      this.details = newVal;
      this.replaceTocke( newVal.mreza )
      this.replaceOdgovori(newVal.zapisi);
    },
    getLayerTocke(newVal) {
      console.log("new getLayerTocke in mapvue", newVal);
    },

  },

  methods: {
    ...mapMutations(["setCoordinates", "setOLMap","sSelTocka","setSelectedPoint"]),
    ...mapActions(['capabilities', 'loadBase','replaceTocke','replaceOdgovori','activateOdgovori',]),

  },

  activated() {
        console.log("activated map");
  },

  deactivated() {
        console.log("deactivated map");
  },

  mounted() {

    this.baseMap = this.getOLMap;

    this.details = this.getDetails;

    if(this.baseMap) {
      this.baseMap.setTarget(this.$refs["map-root"]);

      console.log("remounted map");

      if( this.details.hasOwnProperty('mreza') )
        this.replaceTocke( this.details.mreza )
      if( this.details.hasOwnProperty('zapisi') )
        this.replaceOdgovori( this.details.zapisi )

      return;
    }

    console.log("mounted map");
    this.capabilities();
    this.loadBase();
    
    if( this.details.hasOwnProperty('mreza') )
      this.replaceTocke( this.details.mreza )
    if( this.details.hasOwnProperty('zapisi') )
      this.replaceOdgovori( this.details.zapisi )

    $.support.cors = true;

    var geojson4326 = new GeoJSON({    
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857"
    });

    this.featureOverlay = new VectorLayer({
      source: new VectorSource(),
      map: this.baseMap,
      style: new Style({
        image: new Circle({
          radius: 10,
          stroke: new Stroke({
            color: "#2c3e50",
            width: 2,
          }),
        }),
      }),
    });

    const displayFeatureInfo = (pixel) => {
      const feature = this.baseMap.forEachFeatureAtPixel(pixel, (feature) => {
        return feature;
      });
      if (feature !== this.highlight) {
        if (this.highlight) {
          this.featureOverlay.getSource().removeFeature(this.highlight);
        }
        if (feature) {
          this.featureOverlay.getSource().addFeature(feature);
        }
        this.highlight = feature;
      }
      return feature;
    };

    this.baseMap = new Map({
      interactions: defaultInteractions().extend([new MapInteraciton()]),
      target: this.$refs["map-root"],
      controls: defaultControls({
        attributionOptions: { collapsible: true },
      }).extend([new ScaleLine({ className: "ol-scale-line" })]),
      layers: [this.map_layer, this.mreza_layer, this.odgovori_layer],

      view: new View({
        zoom: 1,
        projection: "EPSG:3857",
        extent: [1440000,5600000, 1880000,6000000],
        //extent: [1455746.1477, 5663030.1293, 1855621.339, 5941967.644],
        //1454249,5627588 : 1874118,5988880
        center: transform([14.83801, 46.11955], "EPSG:4326", "EPSG:3857"),
      }),
    });

    var tooltip = document.getElementById('tooltip');
    var overlay = new Overlay({
      element: tooltip,
      offset: [10, 0],
      positioning: 'bottom-left'
    });
    this.baseMap.addOverlay(overlay);

    this.baseMap.on("pointermove", (evt) => {
      if (evt.dragging) {
        return;
      }
      
      const pixel = this.baseMap.getEventPixel(evt.originalEvent);
      const map = this.baseMap;
      var hit = map.hasFeatureAtPixel(pixel);
      map.getViewport().style.cursor = hit ? 'pointer' : '';
      tooltip.style.display = hit ? '' : 'none';
      if (hit) {
        var feature = displayFeatureInfo(pixel);

        if (feature) {
          overlay.setPosition(evt.coordinate);
          tooltip.innerHTML = feature.get('description');
        }
      }
    });

    this.baseMap.on("click", (evt) => {
      if (evt.dragging) {
        return;
      }

      console.log("click", this.getActiveTab);

      // if logged in
      if( this.loggedIn ) { 
        //newAnswer is a name of a tab for inputing new answers.
        this.setCoordinates({coordinates: evt.coordinate, SRS:"EPSG:3857"});
        // unset selected Tocka
        //?this.$store.commit("sSelTocka", {});
      }
      /*
      const coordinates4326 = transform(
                evt.coordinate,
                "EPSG:3857",
                "EPSG:4326"
            );      
            */
      this.setSelectedPoint(evt.coordinate);
    });

    this.setOLMap(this.baseMap);
    this.activateOdgovori();

  },
};
</script>

<style>
.map-root {
  height: calc(100vh - 161px);
}

.ol-scale-line {
  left: 50%;
  transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
}

.ol-zoom {
  left: unset;
  right: 8px;
}
</style>
