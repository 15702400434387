import "./styles/quasar.sass";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/bootstrap-icons/bootstrap-icons.css";
//import "@quasar/extras/mdi-v5/mdi-v5.css";

import Notify from 'quasar/src/plugins/notify/Notify.js';;

// To be used on app.use(Quasar, { ... })
// To be used on app.use(Quasar, { ... })
export default {
  config: {
    notify: { /* look at QuasarConfOptions from the API card */ }
  },
  plugins: {
    Notify
  },
};
