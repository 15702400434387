<template>
  <div>
    <q-splitter v-model="splitterModel" horizontal>
      <template v-slot:before>
        <q-tabs v-model="tab" horizontal>
          <q-tab name="Legenda" :label='$gettext("Legend")' />
          <q-tab name="Sloji" :label='$gettext("Layers")' />
          <q-tab name="Uporabnik" :label='$gettext("User")' />
        </q-tabs>
      </template>

      <template v-slot:after>
        <q-tab-panels
          v-model="tab"
          animated
          swipeable
          vertical
          transition-prev="jump-up"
          transition-next="jump-up"
        >
          <q-tab-panel name="Uporabnik">
            <user-tab></user-tab>
          </q-tab-panel>
          <q-tab-panel name="Legenda">
            <legend-tab></legend-tab>
          </q-tab-panel>
          <q-tab-panel name="Sloji">
            <layers-tab></layers-tab>
          </q-tab-panel>
          
        </q-tab-panels>
      </template>
    </q-splitter>
  </div>
</template>


<script>
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex"
import store from "../store/index";
import UserTab from "./UserTab";
import LegendTab from "./LegendTab";
import LayersTab from "./LayersTab";


export default {
  name: "RightDrawer",
  components: {
    UserTab,
    LegendTab,
    LayersTab,

  },

  data() {
    return {
      dense: ref(true),
      tab: ref("Sloji"),
      splitterModel: ref(30),
    };
  },

  mounted() {
    //store.dispatch("loadVprDetails")
  },
};
</script>