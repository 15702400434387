
function addIfNotIn(str, el) {
    // split str to array
    // if el not in array add it
    // join array to str
    var a = str.split(", ");
    if(a.indexOf(el) == -1) {
      if(str.length > 0) str += ", ";
      str += el;
    }
    return str;
}

exports.addIfNotIn = (str, el) => addIfNotIn(str, el);

